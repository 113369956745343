/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$font-family: 'IBM Plex Sans', sans-serif;
$main-color: #ff5d22;
$black-color: #524552;
$white-color: #ffffff;
$paragraph-color: #554955;
$font-size: 16px;
$transition: .5s;

body {
    color: $black-color;
    padding: 0;
    margin: 0;
    font: {
        family: $font-family;
        size: $font-size;
    };
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
a {
    color: $black-color;
    transition: $transition;
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}
p {
    line-height: 1.8;
    color: $paragraph-color;
    margin-bottom: 15px;
    font: {
        weight: 300;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
.bg-f8f8f8 {
    background-color: #f8f8f8;
}
:focus {
    outline: 0 !important;
}
button {
    outline: 0 !important;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
}
/*section-title*/
.section-title {
    text-align: center;
    max-width: 750px;
    margin: {
        left: auto;
        right: auto;
        bottom: 50px;
        top: -6px;
    };
    .sub-title {
        color: $main-color;
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    p {
        font-size: 18px;
        max-width: 650px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
            top: 10px;
        };
    }
}
/*default-btn*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    text-transform: uppercase;
    background-color: $main-color;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 600;
        size: $font-size;
    }
    padding: {
        left: 30px;
        right: 30px;
        top: 14px;
        bottom: 14px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $black-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover {
        color: $white-color;

        span {
            width: 225%;
            height: 562.5px;
        }
    }
}
/*optional-btn*/
.optional-btn {
    border: 1px solid $main-color;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $main-color !important;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 600;
        size: $font-size;
    }
    padding: {
        left: 27px;
        right: 27px;
        top: 11px;
        bottom: 11px;
    }
    i {
        margin-right: 2px;
        font-size: 18px;
    }
    &:hover, &:focus {
        color: $white-color !important;
        background-color: $main-color;
    }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: $main-color;
    text-align: center;

    #global {
        position: absolute;
        width: 80px;
        height: 70px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 47%;
        transform: translateY(-47%);

        .mask {
            position: absolute;
            border-radius: 2px;
            overflow: hidden;
            perspective: 1000;
            backface-visibility: hidden;
        }
        .plane {
            background: $white-color;
            width: 400%;
            height: 100%;
            position: absolute;
            transform : translate3d(0px,0,0);
            z-index: 100;
            perspective: 1000;
            backface-visibility: hidden;
        }
        #top {
            .plane{
                z-index: 2000;
                animation: trans1 1.3s ease-in infinite  0s backwards;
            }
        }
        #middle {
            .plane{
                transform: translate3d(0px,0,0);  
                background: #bbbbbb;
                animation: trans2 1.3s linear infinite  0.3s  backwards;
            }
        }
        #bottom {
            .plane{
                z-index: 2000;
                animation: trans3 1.3s ease-out infinite  0.7s backwards;
            }
        }
        #top {
            width: 53px;
            height: 20px;
            left: 20px;
            transform: skew(-15deg, 0);
            z-index: 100;
        }
        #middle {
            width: 33px;
            height: 20px;
            left: 20px;
            top: 15px;
            transform: skew(-15deg, 40deg)
        }
        #bottom {
            width: 53px;
            height: 20px;
            top: 30px;  
            transform: skew(-15deg, 0)
        }
        p {
            color: $white-color;
            position: absolute;
            left: -3px;
            top: 55px;
            text-align: center;
            font: {
                size: 10px;
                family: Arial;
            };
        }
    }
}
@keyframes trans1 { 
    from { 
        transform : translate3d(53px,0,0)
    }
    to { 
        transform : translate3d(-250px,0,0)
    }
}
@keyframes trans2 { 
    from { 
        transform : translate3d(-160px,0,0)
    }
    to { 
        transform : translate3d(53px,0,0)
    }
}
@keyframes trans3 { 
    from { 
        transform : translate3d(53px,0,0)
    }
    to { 
        transform : translate3d(-220px,0,0)
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.zash-nav {
    background-color: transparent;
    
    .navbar {
        background-color: $white-color;
        box-shadow: -10px 10px rgba(255, 255, 255, .60);
        transition: $transition;
        padding: {
            right: 25px;
            top: 0;
            left: 25px;
            bottom: 0;
        }
        .navbar-brand {
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin-left: auto;

            .nav-item {
                position: relative;
                padding: 0;

                a {
                    font: {
                        size: 15px;
                    }
                    transition: $transition;
                    color: $black-color;
                    text-transform: uppercase;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 30px;
                        bottom: 30px;
                    }
                    margin: {
                        left: 13px;
                        right: 13px;
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 11px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;
                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }
                    li {
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            position: relative;
                            color: $black-color;
                            font: {
                                size: 15.5px;
                                weight: 400;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: -5px;
                                top: 50%;
                                width: 0;
                                transition: $transition;
                                height: 1px;
                                background-color: $main-color;
                                transform: translateY(-50%);
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                                padding-left: 26px;

                                &::before {
                                    width: 20px;
                                }
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: $black-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                        
                                                    li {
                                                        a {
                                                            color: $black-color;
                        
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                        
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                        
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-option {
            margin-left: 25px;

            .call-us {
                padding-left: 50px;
                position: relative;
                text-transform: uppercase;
                font: {
                    size: 14px;
                    weight: 300;
                };
                .icon {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    color: $main-color;
                    font-size: 20px;
                    background-color: #f1f4f4;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transition: $transition;
                    transform: translateY(-50%);
                }
                a {
                    display: block;
                    color: $main-color;
                    font-weight: 600;
                }
                &:hover {
                    .icon {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}
.navbar-area {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: auto;
    z-index: 999;
    background-color: transparent;
    transition: $transition;
    
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

        .zash-nav {
            .navbar {
                box-shadow: unset;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
    &.navbar-style-two {
        top: 0;
        padding: {
            left: 40px;
            right: 40px;
        };
        .zash-nav {
            background-color: transparent;
            
            .navbar {
                background-color: transparent;
                box-shadow: unset;
                padding: {
                    right: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                }
                .navbar-nav {
                    margin: {
                        left: auto;
                        right: auto;
                    };
                    .nav-item {
                        a {
                            color: $white-color;
                            
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        &:hover, &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    li {
                                        a {
                                            color: $black-color;
                                            
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            color: $black-color;
                        
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                        
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                        .dropdown-menu {
                                                                            li {
                                                                                a {
                                                                                    color: $black-color;
                                                
                                                                                    &:hover, &:focus, &.active {
                                                                                        color: $main-color;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.active {
                                                                            a {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .others-option {
                    display: flex;
                    align-items: center;

                    .call-us {
                        display: inline-block;
                        color: $white-color;
                        font: {
                            size: 14px;
                            weight: 300;
                        };
                    }
                    .default-btn {
                        margin-left: 25px;
                        background-color: transparent;
                        border: 1px solid $white-color;
                        padding: {
                            top: 12px;
                            bottom: 12px;
                        };
                        span {
                            background-color: $main-color;
                        }
                        &:hover {
                            color: $white-color;
                            border-color: $main-color;
                        }
                    }
                }
            }
        }
        &.is-sticky {
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.2);
            background-color: $black-color !important;
    
            .zash-nav {
                .navbar {
                    background-color: $black-color;
                }
            }
        }
    }
}
.navbar-light {
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
}
.navbar-style-two {
    .navbar-light {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .navbar-area {
        background-color: $white-color;
        position: absolute;
        top: 0;
        padding: {
            top: 15px;
            bottom: 15px;
        };
        &.navbar-style-two {
            padding: {
                left: 0;
                right: 0;
            };
            &.is-sticky {
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                background-color: $black-color !important;
            }
        }
    }
    .zash-nav {
        .navbar {
            box-shadow: unset !important;
            padding: {
                left: 0;
                right: 0;
            };
            .navbar-nav {
                background-color: $white-color;
                border: 1px solid #eeeeee;
                border-bottom: none;
                overflow-y: scroll;
                height: 350px;
                padding: 10px 20px;
                margin: {
                    top: 15px;
                    left: 0;
                };
                .nav-item {
                    a {
                        font-size: 15px;
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        };
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                    .dropdown-menu {
                        box-shadow: unset;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                        border: 1px solid #eeeeee;
                        padding: {
                            top: 5px;
                            left: 10px;
                            right: 10px;
                            bottom: 5px;
                        };
                        li {
                            a {
                                padding: 5px 10px;
                                font-size: 15px;
    
                                &::before {
                                    display: none;
                                }
                                &.active, &:hover, &:focus {
                                    padding-left: 10px;
                                }
                            }
                            .dropdown-menu {
                                left: 10px;
                                top: 0;
                                opacity: 1;
                                visibility: visible;
                                border-right: none;
                            }
                            &:hover {
                                .dropdown-menu {
                                    top: 0;
                                }
                            }
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            top: 0;
                        }
                    }
                }
            }
            .others-option {
                margin-left: 0;
                border: 1px solid #eeeeee;
                padding: {
                    bottom: 20px;
                    left: 20px;
                    right: 20px;
                    top: 20px;
                };
            }
        }
    }
    .navbar-light {
        .navbar-toggler {
            border-color: $black-color;
            box-shadow: unset;
            border-radius: 0;
        }
    }
    .navbar-style-two {
        background-color: $black-color;

        .navbar-light {
            .navbar-toggler {
                border-color: $white-color;
                box-shadow: unset;
            }
        }
        .zash-nav {
            .navbar {
                .navbar-nav {
                    background-color: $black-color;
                    border-color: #685757;
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
                .others-option {
                    border-color: #685757;
                }
            }
        }
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    height: 925px;
    position: relative;
    z-index: 2;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: #1a0602;
        opacity: .60;
    }
}
.item-bg1 {
    background: {
        image: url(assets/img/main-banner1.jpg);
    };
}
.item-bg2 {
    background: {
        image: url(assets/img/main-banner2.jpg);
    };
}
.item-bg3 {
    background: {
        image: url(assets/img/main-banner3.jpg);
    };
}
.item-bg4 {
    background: {
        image: url(assets/img/main-banner4.jpg);
    };
}
.item-bg5 {
    background: {
        image: url(assets/img/main-banner5.jpg);
    };
}
.banner-section {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: #1a0602;
        opacity: .60;
    }
    .main-banner-content {
        margin-top: 30px;
        max-width: 940px;
    }
}
.main-banner-content {
    text-align: center;
    max-width: 900px;
    position: relative;
    z-index: 3;
    margin: {
        left: auto;
        right: auto;
        top: -50px;
    };
    .sub-title {
        text-transform: uppercase;
        display: block;
        color: $white-color;
        letter-spacing: 3px;
        margin-bottom: 8px;
        font: {
            size: 16px;
        };
    }
    h1 {
        color: $white-color;
        margin-bottom: 0;
        font: {
            size: 65px;
            weight: 700;
        };
    }
    .btn-box {
        margin-top: 40px;

        .default-btn {
            span {
                background-color: $white-color;
            }
            &:hover {
                color: $main-color;
            }
        }
        .default-btn, .optional-btn {
            margin: {
                left: 7px;
                right: 7px;
            };
        }
        .optional-btn {
            color: $white-color !important;
            border-color: $white-color;

            &:hover {
                background-color: $white-color;
                color: $main-color !important;
                border-color: $white-color;
            }
        }
    }
    p {
        color: $white-color;
        font-size: 18px;
        margin: {
            bottom: 0;
            top: 30px;
        };
    }
    form {
        position: relative;
        max-width: 800px;
        margin: {
            left: auto;
            top: 15px;
            right: auto;
        };
        .form-group {
            position: relative;

            .input-location {
                display: block;
                width: 75%;
                color: $black-color;
                outline: 0;
                border: none;
                height: 55px;
                padding-left: 65px;
                border-radius: 5px;
                font: {
                    family: $font-family;
                    size: $font-size;
                    weight: 300;
                };
                &::placeholder {
                    color: #524552;
                }
            }
            i {
                color: $main-color;
                font-size: 25px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 25px;
            }
        }
        .default-btn {
            height: 55px;
            line-height: 55px;
            position: absolute;
            top: 0;
            right: 0;
            padding: {
                top: 0;
                bottom: 0;
            };
            i {
                margin-left: 2px;
            }
            span {
                background-color: $white-color;
            }
            &:hover {
                color: $main-color;
            }
        }
    }
}
.home-area {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 350px;
        z-index: 1;
        background: {
            image: url(assets/img/banner-shape.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: 50px;
                top: 40%;
                transform: translateY(-40%);
                background-color: transparent !important;
                transition: $transition;
                margin: 0;
                padding: 0 0 0 0 !important;
                opacity: .50;
                color: $white-color;
                font-size: 30px;
                overflow: hidden;

                &.owl-prev {
                    padding: 0 25px 0 0 !important;

                    &::before {
                        content: 'Prev';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: 'Prev';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                    padding: 0 0 0 25px !important;

                    &::before {
                        content: 'Next';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        transition: transform .4s ease;
                    }
                    &::after {
                        content: 'Next';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0%, 100%);
                        transition: transform .4s ease;
                        font-size: 14px;
                    }
                    &:hover {
                        &::before {
                            transform: translateY(-190%);
                        }
                        &::after {
                            transform: translate(0, -50%);
                        }
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
.home-section {
    position: relative;
    z-index: 1;

    .banner-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        z-index: 1;

        .banner-video {
            text-align: center;

            .video-btn {
                width: 65px;
                height: 65px;
                line-height: 65px;
                color: $main-color;
                cursor: pointer;
                border-radius: 50%;
                z-index: 1;
                position: relative;
                background-color: $white-color;
                display: inline-block;
                font-size: 25px;
    
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
                &::after, &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    border: 1px solid $white-color;
                }
                &::before {
                    animation: ripple 2s linear infinite
                }
                &::after {
                    animation: ripple 2s linear 1s infinite
                }
            }
            span {
                display: inline-block;
                text-transform: uppercase;
                color: $white-color;
                margin-left: 25px;
            }
        }
        .banner-contact-info {
            padding: 35px;
            border-radius: 5px 0 0 0;
            background-color: $main-color;

            ul {
                padding-left: 0;
                list-style-type: none;
                max-width: 770px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    bottom: 0;
                    right: -15px;
                    left: -15px;
                };
                li {
                    position: relative;
                    color: $white-color;
                    padding-left: 65px;
                    font-size: 14px;
                    -ms-flex: 0 0 33.333333%;
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                    padding: {
                        left: 80px;
                        right: 15px;
                    };
                    span {
                        display: block;
                        margin-bottom: 3px;
                        font: {
                            size: 16px;
                            weight: 600;
                        };
                    }
                    i {
                        display: inline-block;
                        position: absolute;
                        left: 15px;
                        top: 0;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        transition: $transition;
                        border-radius: 50%;
                        color: $white-color;
                        background-color: #bc5435;
                        text-align: center;
                        font-size: 20px;
                    }
                    a {
                        display: block;
                        color: $white-color;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        i {
                            background-color: $white-color;
                            color: $main-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-title {
    border-right: 1px solid #dfdddf;
    padding-right: 35px;

    span {
        color: $main-color;
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 700;
        };
    }
}
.about-text {
    padding-left: 35px;

    p {
        margin-bottom: 0;
    }
    .read-more-btn {
        margin-top: 18px;
        display: inline-block;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 600;
        };
        i {
            transition: $transition;
            display: inline-block;
        }
        &:hover {
            i {
                margin-left: 3px;
            }
        }
    }
}
.about-section {
    .container-fluid {
        padding: 0;

        .row {
            margin: 0;

            .col-lg-6 {
                padding: 0;
            }
        }
    }
}
.about-image {
    position: relative;
    text-align: right;

    .img1, .img2, .img3 {
        display: inline-block;
    }
    .img1 {
        position: relative;
        z-index: 1;
        padding-top: 25px;

        &::before {
            content: '';
            position: absolute;
            left: -25px;
            top: 0;
            width: 100%;
            height: 95%;
            border: 8px solid $main-color;
            z-index: -1;
        }
    }
    .img2 {
        position: absolute;
        left: 0;
        top: 55px;
        z-index: 1;
        border: 5px solid $white-color;
        border-left: none;
    }
    .img3 {
        position: absolute;
        left: 0;
        bottom: 55px;
        z-index: 1;
        border: 5px solid $white-color;
        border-left: none;
    }
}
.about-content {
    max-width: 555px;
    padding-left: 50px;

    .sub-title {
        color: $main-color;
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    p {
        margin: {
            top: 15px;
            bottom: 0;
        };
    }
    .read-more-btn {
        margin-top: 20px;
        display: inline-block;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 600;
        };
        i {
            transition: $transition;
            display: inline-block;
        }
        &:hover {
            i {
                margin-left: 3px;
            }
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            left: -15px;
            right: -15px;
            bottom: 20px;
            top: 0;
        };
        li {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            position: relative;
            padding: {
                left: 70px;
                right: 15px;
                top: 20px;
            };
            .icon {
                font-size: 35px;
                color: $main-color;
                position: absolute;
                left: 15px;
                top: 20px;
            }
            span {
                display: block;
                margin-bottom: 6px;
                font: {
                    size: 17px;
                    weight: 600;
                };
            }
        }
    }
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
    background-color: $main-color;
    position: relative;
    z-index: 1;

    &::before {
        width: 100%;
        z-index: -1;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $white-color;
        height: 30%;
    }
}
.video-content {
    text-align: center;
    max-width: 780px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    p {
        color: $white-color;
        max-width: 585px;
        margin: {
            bottom: 0;
            top: 15px;
            left: auto;
            right: auto;
        };
    }
}
.video-box {
    position: relative;
    border-radius: 5px;
    max-width: 980px;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
        top: 55px;
    };
    img {
        border-radius: 5px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        opacity: .50;
        border-radius: 5px;
    }
    .play-video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        .video-btn {
            width: 65px;
            height: 65px;
            line-height: 65px;
            color: $main-color;
            cursor: pointer;
            border-radius: 50%;
            z-index: 1;
            position: relative;
            background-color: $white-color;
            display: inline-block;
            font-size: 25px;

            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
            &::after, &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid $white-color;
            }
            &::before {
                animation: ripple 2s linear infinite
            }
            &::after {
                animation: ripple 2s linear 1s infinite
            }
        }
        span {
            display: block;
            text-transform: uppercase;
            color: $white-color;
            margin-top: 25px;
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1)
    }
    75% {
        transform: scale(1.75);
        opacity: 1
    }
    100% {
        transform: scale(2);
        opacity: 0
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    padding-bottom: 70px;
}
.single-services-box {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    padding: 30px;
    transition: $transition;

    .icon {
        font-size: 65px;
        transition: $transition;
        position: relative;
        z-index: 1;
        margin: {
            top: -15px;
            bottom: 10px;
        };
        .icon-bg {
            img {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
                width: auto !important;
                display: inline-block !important;
                z-index: -1;
            }
        }
    }
    h3 {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 13px;
        transition: $transition;
        font: {
            size: 24px;
            weight: 600;
        };
        a {
            display: inline-block;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            background-color: #e9e9e9;
            height: 2px;
            width: 60px;
            transition: $transition;
        }
    }
    p {
        transition: $transition;
        margin: {
            top: 0;
            bottom: 0;
        };
    }
    .read-more-btn {
        display: inline-block;
        margin-top: 15px;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 600;
        };
        &:hover {
            letter-spacing: .5px;
        }
    }
    .box-shape {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: auto !important;
            transition: $transition;
            display: inline-block !important;

            &:nth-child(2) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &:hover {
        border-color: $black-color;
        background-color: $black-color;

        .icon {
            color: $main-color;
        }
        h3 {
            &::before {
                background-color: $main-color;
            }
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .box-shape {
            img {
                &:nth-child(1) {
                    opacity: 0;
                    visibility: hidden;
                }
                &:nth-child(2) {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
.services-box {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px;
    background-color: $black-color;

    img {
        border-radius: 5px;
    }
    .services-content {
        padding: 30px;

        h3 {
            margin-bottom: 15px;
            position: relative;
            padding-bottom: 13px;
            color: $white-color;
            font: {
                size: 24px;
                weight: 600;
            };
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0 auto;
                background-color: $white-color;
                height: 2px;
                width: 60px;
                transition: $transition;
            }
        }
        p {
            color: $white-color;
            margin: {
                top: 0;
                bottom: 0;
            };
        }
    }
}
.services-slides {
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;

            [class*=owl-] {
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: #bcbcbc;
                font-size: 25px;

                &.owl-next {
                    left: auto;
                    right: -30px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: 5px;
                bottom: 30px;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $black-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.main-services-slides {
    &.owl-theme {
        .owl-dots {
            line-height: .01;
            margin: {
                top: 10px !important;
                bottom: 30px;
            };
            .owl-dot {
                span {
                    width: 16px;
                    height: 16px;
                    margin: 0 3px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $main-color;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $main-color;
                        border-radius: 50%;
                        margin: 2px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
.services-boxes-area {
    margin-top: -180px;
    position: relative;
    z-index: 3;
}
.single-box {
    text-align: center;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    img {
        border-radius: 5px;
        transition: $transition;
    }
    .content {
        background-color: $main-color;
        padding: 65px 35px 50px;
        position: absolute;
        left: 0;
        transition: $transition;
        height: auto;
        width: 100%;
        bottom: 0;
        border-radius: 100% 100% 5px 5px;

        h3 {
            margin-bottom: 0;
            position: relative;
            padding-bottom: 15px;
            transition: $transition;
            color: $white-color;
            font: {
                size: 24px;
                weight: 600;
            };
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0 auto;
                background-color: $white-color;
                height: 2px;
                width: 60px;
                transition: $transition;
            }
        }
    }
    .hover-content {
        background-color: $main-color;
        padding: 65px 35px 50px;
        position: absolute;
        left: 0;
        height: auto;
        width: 100%;
        bottom: -138px;
        border-radius: 100% 100% 5px 5px;
        transition: $transition;
        opacity: 0;
        visibility: hidden;

        h3 {
            margin-bottom: 15px;
            position: relative;
            padding-bottom: 15px;
            transition: $transition;
            color: $white-color;
            font: {
                size: 24px;
                weight: 600;
            };
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0 auto;
                background-color: $white-color;
                height: 2px;
                width: 60px;
                transition: $transition;
            }
        }
        p {
            transition: $transition;
            color: $white-color;
            margin: {
                top: 0;
                bottom: 0;
            };
        }
        .read-more-btn {
            display: inline-block;
            margin-top: 15px;
            color: $white-color;
            position: relative;
            font: {
                size: $font-size;
                weight: 600;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: $black-color;
            }
        }
    }
    &:hover {
        .content {
            opacity: 0;
            visibility: hidden;
        }
        .hover-content {
            opacity: 1;
            visibility: visible;
            bottom: 0;
        }
        img {
            transform: scale(1.3) rotate(4deg);
        }
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
    -ms-flex-align: center!important;
    align-items: center!important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        right: -25px;
        left: -25px;
        bottom: 60px;
    };
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 25px;
            left: 25px;
        };
        h3 {
            margin-bottom: 15px;
            font: {
                size: 24px;
                weight: 600;
            };
        }
        .features-text {
            margin-top: 25px;
    
            h4 {
                margin-bottom: 15px;
                font: {
                    size: 17px;
                    weight: 600;
                };
                i {
                    font-size: 15px;
                    margin-right: 4px;
                    color: $main-color;
                }
            }
        }
        .services-details-accordion {
            margin-top: 25px;

            .accordion {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    bottom: 0;
                };
                .accordion-item {
                    display: block;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                    background: $white-color;
                    margin-bottom: 10px;
        
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                        .accordion-title {
                            i {
                                background: $black-color;
                            }
                        }
                    }
                }
                .accordion-title {
                    padding: 15px 20px 15px 51px;
                    color: $black-color;
                    position: relative;
                    border-bottom: 1px solid transparent;
                    margin-bottom: -1px;
                    display: inline-block;
                    font: {
                        size: $font-size;
                        weight: 600;
                    };
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        text-align: center;
                        height: 100%;
                        background: $main-color;
                        color: $white-color;
        
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 13px;
                        }
                    }
                    &.active {
                        border-bottom-color: #eeeeee;
        
                        i {
                            &::before {
                                content: "\f068";
                            }
                        }
                    }
                }
                .accordion-content {
                    display: none;
                    position: relative;
                    padding: 15px 15px;
        
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
    .services-details-image {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 15px;
            left: 15px;
        };
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    &.ptb-100 {
        &.bg-f8f8f8 {
            padding: {
                top: 70px;
                bottom: 70px;
            };
        }
    }
}
.partner-title {
    text-align: center;

    h2 {
        text-transform: uppercase;
        margin-bottom: 40px;
        font: {
            size: 22px;
            weight: 600;
        };
    }
}
.single-partner-item {
    text-align: center;

    a {
        display: block;

        img {
            width: auto !important;
            display: inline-block !important;
            transition: $transition;
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.1);
            }
        }
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.shorting-menu {
    text-align: center;
    margin-bottom: 40px;

    .filter {
        border: none;
        background-color: #f5f5f5;
        border-radius: 5px;
        transition: $transition;
        color: $black-color;
        padding: 10px 20px;
        margin: {
            left: 5px;
            right: 5px;
        };
        font: {
            size: 15px;
            weight: 600;
        };
        &:hover, &.mixitup-control-active {
            background-color: $main-color;
            color: $white-color;
        }
        &:last-child {
            margin-right: 0;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}
.gallery-area {
    .container-fluid {
        padding: 0;

        .row {
            margin: 0;
        }
    }
}
.single-gallery-box {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center;
    position: relative;
    padding: 0;

    &::before {
        width: 100%;
        content: '';
        position: absolute;
        height: 100%;
        background-color: $main-color;
        left: 0;
        top: 0;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }
    .gallery-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: $white-color;
        color: $black-color;
        font-size: 18px;
        margin-top: 15px;
        opacity: 0;
        visibility: hidden;

        &:hover {
            color: $white-color;
            background-color: $black-color;
        }
    }
    &:hover {
        &::before {
            opacity: .85;
            visibility: visible;
        }
        .gallery-btn {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-area {
    .container-fluid {
        padding: 0;

        .row {
            margin: 0;

            .col-lg-5, .col-lg-7 {
                padding: 0;
            }
        }
    }
}
.why-choose-us-image {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: {
        image: url(assets/img/why-choose-img1.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.why-choose-us-content {
    background-color: $black-color;

    .content {
        max-width: 705px;
        overflow: hidden;
        padding: {
            left: 70px;
            top: 100px;
            bottom: 100px;
        }
        .sub-title {
            color: $main-color;
            font-size: 18px;
            display: block;
            margin-bottom: 8px;
        }
        h2 {
            color: $white-color;
            margin-bottom: 0;
            font: {
                size: 42px;
                weight: 700;
            };
        }
        p {
            color: $white-color;
            font-size: 18px;
            margin: {
                bottom: 0;
                top: 10px;
            };
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: {
                left: -15px;
                right: -15px;
                bottom: 0;
                top: 0;
            };
            li {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                color: #e5e5e5;
                position: relative;
                padding: {
                    left: 80px;
                    right: 15px;
                    top: 30px;
                };
                .icon {
                    position: absolute;
                    left: 15px;
                    top: 35px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    transition: $transition;
                    border-radius: 50%;
                    color: $main-color;
                    background-color: #352735;
                    text-align: center;
                    font-size: 20px;
                }
                span {
                    display: block;
                    margin-bottom: 6px;
                    color: $white-color;
                    font: {
                        size: 18px;
                        weight: 600;
                    };
                }
                &:hover {
                    .icon {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Benefits Area CSS
=================================================*/
.single-benefits-box {
    padding: 25px 14px;
    margin-bottom: 22px;
    background-color: #f8f8f8;
    transition: $transition;
    text-align: center;

    &:last-child {
        margin-bottom: 0;
    }
    h3 {
        margin-bottom: 0;
        transition: $transition;
        font: {
            size: 18px;
            weight: 600;
        };
    }
    p {
        transition: $transition;
        margin: {
            top: 10px;
        };
    }
    .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        transition: $transition;
        border-radius: 50%;
        color: $main-color;
        background-color: #352735;
        text-align: center;
        font-size: 25px;
        display: inline-block;
        margin-bottom: 15px;
    }
    &:hover {
        background-color: $main-color;

        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .icon {
            background-color: $white-color;
            color: $main-color;
        }
    }
}
.benefits-image {
    text-align: center;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
    padding-bottom: 70px;
}
.single-pricing-box {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,.11);
    border-radius: 5px;
    text-align: center;
    padding-bottom: 50px;

    .pricing-header {
        background-color: $black-color;
        border-radius: 5px 5px 0 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: {
            top: 90px;
            bottom: 90px;
        };
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &.bg1 {
            background: {
                image: url(assets/img/place-img1.jpg);
            };
        }
        &.bg2 {
            background: {
                image: url(assets/img/place-img2.jpg);
            };
        }
        &.bg3 {
            background: {
                image: url(assets/img/place-img3.jpg);
            };
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            z-index: -1;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: $black-color;
            opacity: .75;
        }
        h3 {
            margin-bottom: 0;
            color: $white-color;
            position: relative;
            z-index: 1;
            font: {
                weight: 600;
                size: 24px;
            };
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 105px;
            width: 100%;
            background: {
                image: url(assets/img/pricing-shape.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
    }
    .price {
        color: $black-color;
        margin-top: 20px;
        font: {
            size: 40px;
            weight: 700;
        };
        sub {
            text-transform: uppercase;
            bottom: 2px;
            font: {
                weight: 300;
                size: 18px;
            };
        }
    }
    .book-now-btn {
        margin-top: 20px;

        .default-btn {
            background-color: transparent;
            color: $main-color;
            border: 1px solid $main-color;
            padding: {
                left: 35px;
                right: 35px;
                top: 12px;
                bottom: 12px;
            };
            span {
                background-color: $main-color;
            }
            &:hover {
                color: $white-color;
            }
        }
    }
    .pricing-features-list {
        margin: {
            bottom: 0;
            top: 25px;
        };
        list-style-type: none;
        text-align: left;
        padding: {
            left: 40px;
            right: 40px;
        };
        li {
            color: $black-color;
            margin-bottom: 12px;
            font: {
                size: $font-size;
                weight: 300;
            };
            i {
                color: $main-color;
                margin-right: 2px;

                &.flaticon-cross-out {
                    font-size: 14px;
                }
            }
            &.none-active {
                opacity: .76;

                i {
                    color: $black-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Join Area CSS
=================================================*/
.join-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/join-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $black-color;
        opacity: 0.75;
    }
}
.join-content {
    text-align: center;
    max-width: 650px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        margin-bottom: 15px;
        color: $white-color;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    p {
        color: $white-color;
        margin: {
            bottom: 0;
        };
    }
    .default-btn {
        margin-top: 25px;

        span {
            background-color: $white-color;
        }
        &:hover, &:focus {
            color: $main-color;
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    padding-bottom: 70px;
}
.single-feedback-item {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    padding-top: 75px;

    p {
        position: relative;
        max-width: 790px;
        line-height: 1.5;
        margin: {
            bottom: 0;
            left: auto;
            right: auto;
        };
        font: {
            size: 28px;
            weight: normal;
            style: italic;
        };
        &::before {
            content: '“';
            position: relative;
            display: inline-block;
            left: 0;
            top: 0;
            font-size: 30px;
            line-height: 0;
        }
        &::after {
            content: '”';
            position: relative;
            display: inline-block;
            right: 0;
            bottom: 0;
            font-size: 30px;
            line-height: 0;
        }
    }
    .client {
        margin-top: 30px;

        img {
            width: auto !important;
            display: inline-block !important;
        }
    }
    &::before {
        position: absolute;
        left: 0;
        right: 0;
        content: "\f10e";
        top: 0;
        color: $main-color;
        display: inline-block;
        margin: {
            top: -27px;
            left: auto;
            right: auto;
        };
        font: {
            size: 70px;
            family: Flaticon;
            style: normal;
        };
    }
}
.feedback-slides {
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;

            [class*=owl-] {
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: #bcbcbc;
                font-size: 25px;

                &.owl-next {
                    left: auto;
                    right: -30px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: 0px;
                bottom: 30px;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $black-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Our Mission Area CSS
=================================================*/
.our-mission-area {
    background-color: $black-color;
}
.mission-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &.bg-1 {
        background: {
            image: url(assets/img/mission-img1.jpg);
        };
    }
    &.bg-2 {
        background: {
            image: url(assets/img/mission-img2.jpg);
        };
    }
    img {
        display: none;
    }
}
.mission-text {
    text-align: center;
    padding: {
        top: 70px;
        bottom: 67px;
        left: 30px;
        right: 30px;
    };
    .icon {
        color: $main-color;
        font-size: 60px;
        margin-bottom: 10px;
    }
    h3 {
        color: $white-color;
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 10px;
        font: {
            size: 24px;
            weight: 600;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 60px;
            margin: 0 auto;
            bottom: 0;
            background-color: $main-color;
            height: 1px;
        }
    }
    p {
        color: $white-color;
        margin-bottom: 0;
    }
    .default-btn {
        margin-top: 20px;

        span {
            background-color: $white-color;
        }
        &:hover, &:focus {
            color: $main-color;
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    padding-bottom: 70px;
}
.single-team-box {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px;
    transition: $transition;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.07);

    .image {
        position: relative;
        overflow: hidden;
        border-radius: 5px;

        img {
            border-radius: 5px;
            transition: $transition;
        }
        .social {
            margin-bottom: 0;
            z-index: 1;
            list-style-type: none;
            position: absolute;
            left: 50%;
            bottom: -100%;
            transform: translateX(-50%);
            overflow: hidden;
            background-color: $main-color;
            border-radius: 5px;
            transition: $transition;
            padding: {
                left: 10px;
                right: 10px;
                top: 7px;
                bottom: 7px;
            };
            li {
                display: inline-block;
                margin: {
                    left: 7px;
                    right: 7px;
                };
                a {
                    display: inline-block;
                    color: $white-color;
                }
            }
        }
    }
    .content {
        padding: 25px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 24px;
                weight: 600;
            };
        }
        span {
            display: block;
            margin-top: 4px;
            font: {
                size: $font-size;
                weight: 300;
            };
        }
    }
    &:hover {
        .image {
            img {
                transform: scale(1.2) rotate(4deg);
            }
            .social {
                bottom: 15px;
            }
        }
    }
}
.team-slides {
    &.owl-theme {
        .owl-item {
            .single-team-box {
                box-shadow: unset;
                margin-bottom: 40px;
            }
            &.active {
                &.center {
                    .single-team-box {
                        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.07);

                        .social {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                            bottom: 15px;
                        }
                    }
                }
            }
        }
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;

            [class*=owl-] {
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: #bcbcbc;
                font-size: 25px;

                &.owl-next {
                    left: auto;
                    right: -30px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            margin: {
                top: -5px;
                bottom: 30px;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $black-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Event Area CSS
=================================================*/
.event-area {
    background-color: #fafafa;
}
.single-event-box {
    display: flex;
    flex-wrap: wrap;
    background-color: $white-color;    

    .event-image {
        flex: 0 0 50%;
        max-width: 50%;

        .image {
            width: 100%;
            height: 100%;
            position: relative;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
            img {
                display: none;
            }
            &.bg1 {
                background: {
                    image: url(assets/img/blog-image/img1.jpg);
                };
            }
            &.bg2 {
                background: {
                    image: url(assets/img/blog-image/img2.jpg);
                };
            }
            &.bg3 {
                background: {
                    image: url(assets/img/blog-image/img3.jpg);
                };
            }
            &.bg4 {
                background: {
                    image: url(assets/img/blog-image/img4.jpg);
                };
            }
        }
    }
    .event-content {
        flex: 0 0 50%;
        max-width: 50%;

        .content {
            padding: 40px 30px;

            .date {
                color: $main-color;
                margin-top: -5px;
                font: {
                    size: 50px;
                    weight: 300;
                };
                sup {
                    text-transform: uppercase;
                    color: $black-color;
                    top: -22px;
                    left: -6px;
                    font: {
                        size: 18px;
                        weight: 500;
                    };
                }
                sub {
                    font-size: 15px;
                    left: -40px;
                    top: -1px;
                    color: #818181;
                }
            }
            h3 {
                margin-bottom: 11px;
                font: {
                    size: 24px;
                    weight: 600;
                };
                a {
                    display: inline-block;
                }
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    bottom: 0;
                    left: -15px;
                    right: -15px;
                    top: 10px;
                };
                li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    color: $paragraph-color;
                    padding: {
                        left: 15px;
                        right: 15px;
                    };
                    span {
                        display: block;
                        margin-bottom: 5px;
                        font: {
                            size: 18px;
                            weight: 600;
                        };
                    }
                }
            }
            .default-btn {
                margin-top: 25px;
            }
        }
    }
}

/*================================================
Event Details Area CSS
=================================================*/
.event-details-area {
    .widget-area {
        padding-left: 25px;
        margin-top: 123px;
    }
}
.event-details {
    .event-details-header {
        margin-bottom: 20px;

        .back-all-event {
            display: inline-block;
            color: $paragraph-color;
            text-transform: uppercase;
            margin-bottom: 15px;
            font-weight: 600;

            &:hover {
                color: $main-color;
            }
        }
        h3 {
            margin-bottom: 15px;
            font: {
                size: 26px;
                weight: 600;
            };
        }
        .event-info-meta {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin-right: 10px;
                color: $paragraph-color;

                i {
                    color: $main-color;
                    margin-right: 2px;
                }
            }
        }
    }
    .event-details-image {
        margin-bottom: 20px;
    }
    .event-info-links {
        margin-top: 25px;

        a {
            display: inline-block;
            background-color: $main-color;
            color: $white-color;
            border-radius: 5px;
            padding: 5px 15px 6px;
            margin-right: 5px;
        }
    }
    .post-navigation {
        margin-top: 35px;
    }
}

/*================================================
Place Area CSS
=================================================*/
.place-area {
    .container-fluid {
        padding: 0;

        .row {
            margin: 0;

            .col-lg-6 {
                padding: 0;
            }
        }
    }
}
.place-content {
    background-color: $black-color;
    text-align: center;
    padding: {
        top: 100px;
        bottom: 100px;
        left: 30px;
        right: 30px;
    };
    .sub-title {
        color: $main-color;
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    h2 {
        color: $white-color;
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    p {
        color: $white-color;
        font-size: 18px;
        max-width: 655px;
        margin: {
            bottom: 0;
            top: 10px;
            left: auto;
            right: auto;
        };
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            left: -15px;
            right: -15px;
            bottom: 0;
            top: 0;
        };
        li {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            color: #e5e5e5;
            position: relative;
            padding: {
                left: 15px;
                right: 15px;
                top: 30px;
            };
            .icon {
                color: $main-color;
                font-size: 45px;
            }
            span {
                display: block;
                margin-bottom: 10px;
                color: $white-color;
                font: {
                    size: 24px;
                    weight: 600;
                };
            }
        }
    }
}
.single-place-image {
    width: 100%;
    height: 100%;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &.bg1 {
        background: {
            image: url(assets/img/place-img1.jpg);
        };
    }
    &.bg2 {
        background: {
            image: url(assets/img/place-img2.jpg);
        };
    }
    &.bg3 {
        background: {
            image: url(assets/img/place-img3.jpg);
        };
    }
    img {
        width: auto !important;
        display: none !important;
    }
}
.place-image-slides {
    height: 100%;

    .owl-stage-outer {
        width: 100%;
        height: 100%;

        .owl-stage {
            height: 100%;

            .owl-item {
                height: 100%;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            opacity: 0;
            visibility: hidden;
            margin-top: 0;
            transition: $transition;

            [class*=owl-] {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                background-color: transparent;
                transition: $transition;
                color: $white-color;
                font-size: 35px;

                &.owl-next {
                    left: auto;
                    right: 25px;
                }
                &:hover {
                    color: $main-color;
                    background-color: transparent;
                }
            }
        }
        .owl-dots {
            line-height: .01;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: {
                top: 0;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 2px;
                    margin: 0 3px;
                    background-color: $white-color;
                    transition: $transition;
                    border-radius: 0;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                        width: 25px;
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Floor Plans CSS
=================================================*/
.floor-plans-area {
    background: {
        image: url(assets/img/background.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.tabs_item {
    display: none;

    &:first-child {
        display: block;
    }
}
.tab {
    .tabs {
        text-align: center;
        padding: 0;
        margin: 0 0 40px;
        list-style-type: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin: {
            left: -15px;
            right: -15px;
        }
        li {
            display: block;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            position: relative;
            z-index: 1;
            padding: {
                right: 15px;
                left: 15px;
            }
            &::before {
                width: 30px;
                height: 30px;
                left: 0;
                bottom: -8px;
                position: absolute;
                background: $main-color;
                content: '';
                transform: rotate(45deg);
                right: 0;
                margin: 0 auto;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                transition: $transition;
            }
            a {
                display: block;
                background-color: $white-color;
                padding: 18px 40px;
                color: $black-color;
                text-transform: uppercase;
                font: {
                    weight: 600;
                    size: $font-size;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &.current {
                a {
                    background-color: $main-color;
                    color: $white-color;
                }
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .tab_content {
        background-color: $white-color;
        padding: 35px;

        .tabs_item {
            .tabs_item_content {
                h3 {
                    margin-bottom: 12px;
                    font: {
                        size: 24px;
                        weight: 600;
                    };
                }
                .features-list {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        top: 25px;
                        bottom: 0;
                    };
                    li {
                        margin-bottom: 12px;
                        color: $paragraph-color;
                        font-weight: 500;
                        overflow: hidden;
                        padding-bottom: 12px;
                        border-bottom: 1px solid #eeeeee;

                        span {
                            float: right;
                            color: $black-color;
                            font-weight: 600;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .default-btn {
                    margin-top: 30px;
                }
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,.11);
    border-radius: 5px;

    .post-image {
        position: relative;

        a {
            display: block;

            img {
                border-radius: 5px;
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $white-color;
            border-radius: 0 5px 0 0;
            padding: 10px 25px 0;
            z-index: 1;
            font: {
                size: 14px;
                weight: 300;
            };
            i {
                color: $main-color;
                margin-right: 2px;
            }
        }
    }
    .post-content {
        padding: 25px;

        h3 {
            margin-bottom: 10px;
            line-height: 1.3;
            font: {
                size: 24px;
                weight: 600;
            };
        }
        p {
            margin-bottom: 0;
        }
        .default-btn {
            margin-top: 20px;
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    opacity: .80;
                }
            }
        }
    }
}
.blog-notes {
    margin-top: 10px;
    text-align: center;

    p {
        line-height: initial;

        a {
            display: inline-block;
            color: $main-color;

            &:hover {
                color: $black-color;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;
    
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
    
                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 21px;

                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;
    
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            }
            font: {
                size: 24px;
                weight: 600;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;
                    margin-left: 5px;

                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        font-size: 14px;
        
                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 24px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .nav-previous {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            
            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            
            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    weight: 600;
                }
            }
        }
    }
}
.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;

    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 24px;
            weight: 600;
        };
        &::before {
            content: '';
            height: 25px;
            width: 3px;
            left: -25px;
            position: absolute;
            background: $main-color;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 6px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                position: relative;
                z-index: 1;
                font: {
                    size: 13px;
                    weight: 600;
                };
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 0.1em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font: {
                size: 24px;
                weight: 600;
            }
            &::before {
                content: '';
                height: 25px;
                width: 3px;
                left: -25px;
                position: absolute;
                background: $main-color;
                top: 50%;
                transform: translateY(-50%);
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 600;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 6px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 13px 30px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: $transition;
                font: {
                    weight: 700;
                    size: 16px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    height: 450px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: #1a0602;
        opacity: .60;
    }
}
.page-title-content {
    text-align: center;
    margin-top: 140px;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 42px;
            weight: 700;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        };
        li {
            color: $white-color;
            display: inline-block;
            position: relative;
            font: {
                weight: 400;
                size: 17px;
            };
            margin: {
                left: 12px;
                right: 12px;
            };
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: 11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-color;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 35px;
    text-align: center;

    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 49px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            weight: 700;
        }
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font: {
                weight: 600;
                size: 22px;
            }
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_risox_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog-image/img7.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog-image/img8.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog-image/img9.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 3px;
                    }
                    font: {
                        size: 11px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 500;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 600;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 600;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: $black-color;
                font: {
                    size: 15.5px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 600;
                size: 14.5px !important;
            }
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .widget_event_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                border-bottom: 1px solid #eeeeee;
                color: #888f96;
                padding: {
                    bottom: 10px;
                    top: 10px;
                };
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 600;
                    margin-right: 2px;
                }
                a {
                    display: inline-block;
                    color: #888f96;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #493f49;
}
.subscribe-content {
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 32px;
            weight: 600
        };
    }
    p {
        color: $white-color;
        opacity: 0.9;
        margin: {
            top: 10px;
            bottom: 0;
        };
    }
}
.subscribe-form {
    padding-left: 30px;

    form {
        position: relative;

        .input-newsletter {
            display: block;
            width: 100%;
            color: $black-color;
            height: 65px;
            border-radius: 5px;
            border: none;
            padding-left: 15px;
            outline: 0;
            box-shadow: unset !important;
            font: {
                size: $font-size;
                weight: 500;
                family: $font-family;
            };
            &::placeholder {
                color: #99afc3;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            border-radius: 5px;
            height: 55px;
            line-height: 55px;
            padding: 0 30px;
            letter-spacing: .5px;
            transition: $transition;
            font: {
                size: $font-size;
                family: $font-family;
                weight: 600;
            };
            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    background-color: $black-color;
    padding: {
        top: 260px;
        bottom: 100px;
    };
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        color: $white-color;
        font: {
            size: 40px;
            weight: 700;
        }
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
        color: $white-color;
    }
    .default-btn {
        span {
            background-color: $white-color;
        }
        &:hover {
            color: $black-color;
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;

    .section-title {
        margin-bottom: 40px;
        text-align: left;
        margin: {
            left: 0;
            right: 0;
        };
    }
}
.contact-form {
    form {
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
            height: 57px;
        }
        .form-group {
            text-align: left;
        }
        .row {
            margin: {
                left: -7px;
                right: -7px;
            }
            .col-lg-12, .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
        .default-btn {
            margin-top: 10px;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5) !important;
        }
    }
}
.contact-info {
    padding-right: 25px;
    
    .contact-list {
        margin-bottom: 0;
        list-style-type: none;
        padding: {
            right: 20px;
            left: 0;
        };
        li {
            position: relative;
            padding-left: 95px;
            color: $paragraph-color;
            margin-bottom: 25px;

            .icon {
                border: 1px dashed $main-color;
                width: 75px;
                height: 75px;
                line-height: 74px;
                color: $white-color;
                border-radius: 50%;
                font-size: 25px;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: $main-color;
                    border-radius: 50%;
                    margin: 6px;
                    z-index: -1;
                }
            }
            span {
                display: block;
                margin-bottom: 5px;
                color: $black-color;
                font: {
                    size: 22px;
                    weight: 600;
                }
            }
            a {
                color: $paragraph-color;
                display: block;

                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding-left: 95px;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 20px;
        };
        li {
            display: inline-block;
            margin-right: 5px;

            a {
                display: inline-block;
                background-color: #f1f4f4;
                color: $main-color;
                width: 50px;
                font-size: 17px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
                text-align: center;

                i {
                    &.flaticon-facebook {
                        display: inline-block;
                        padding-left: 5px;
                    }
                    &.flaticon-twitter {
                        display: inline-block;
                        position: relative;
                        top: 2px;
                    }
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.bg-map {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    text-align: center;
    margin: 0 auto;
    opacity: .4;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: #2e282e;
    padding-top: 70px;
}
.single-footer-widget {
    margin-bottom: 30px;

    h3 {
        margin-bottom: 30px;
        color: $white-color;
        position: relative;
        padding-bottom: 8px;
        border-bottom: 1px solid #493f49;
        font: {
            size: 24px;
            weight: 600;
        }
        &::before {
            bottom: -1px;
            left: 0;
            width: 55px;
            height: 1px;
            background-color: $main-color;
            content: '';
            position: absolute;
        }
    }
    .logo {
        a {
            display: inline-block;
        }
        p {
            color: #ebe7e7;
            margin: {
                bottom: 0;
                top: 15px;
            };
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 20px;
        };
        li {
            display: inline-block;
            margin-right: 5px;

            a {
                display: inline-block;
                background-color: #493f49;
                color: $main-color;
                width: 35px;
                font-size: 15px;
                height: 35px;
                line-height: 35px;
                border-radius: 50%;
                text-align: center;

                i {
                    &.flaticon-facebook {
                        display: inline-block;
                        padding-left: 4px;
                    }
                    &.flaticon-twitter {
                        display: inline-block;
                        position: relative;
                        top: 2px;
                    }
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .footer-quick-links {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            bottom: 0;
            top: -12px;
        };
        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: {
                left: 15px;
                right: 15px;
                top: 12px;
            };
            a {
                display: inline-block;
                color: #ebe7e7;
                text-transform: uppercase;
                font-size: 14px;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
        };
        li {
            color: #ebe7e7;
            font-size: 15px;
            margin-bottom: 12px;

            span {
                display: inline-block;
                font-weight: 700;
                color: $white-color;
                margin-right: 2px;
            }
            a {
                display: inline-block;
                color: $white-color;
                position: relative;

                &::before {
                    width: 100%;
                    height: 1px;
                    background-color: $main-color;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;

                a {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.copyright-area {
    margin-top: 40px;
    border-top: 1px solid #493f49;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        color: #ebe7e7;
        font-size: 15px;

        a {
            color: $white-color;
            display: inline-block;
            font-weight: 600;

            &:hover {
                color: $main-color;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: right;

        li {
            display: inline-block;
            color: #ebe7e7;
            font-size: 15px;
            position: relative;
            margin: {
                left: 10px;
                right: 10px;
            };
            a {
                display: inline-block;
                color: #ebe7e7;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                top: 4px;
                right: -11px;
                width: 1px;
                height: 14px;
                background-color: $white-color;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
    top: 0;
	right: 15px;
	color: $white-color;
	background-color: $black-color;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;
    
    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;

        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    &:hover, &:focus {
        color: $white-color;

        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}